<template>
  <div class="container">
    <div class="top"></div>
    <div class="bottom">
      <div class="content">
        <input type="text" class="phone" placeholder="请输入手机号" v-model="phone">
        <div class="row">
          <input type="text" class="vcode" placeholder="请输入验证码" v-model="vcode">
          <div class="getBtn"  @click="sendCaptcha()" :class="[!sendCaptchaEnabled?'getBtnColor':'']">
            <span v-if="sendCaptchaEnabled">发送验证码</span>
            <span v-else>{{counterTimer}}s</span>
          </div>
        </div>   
        <!-- <div class="getBtn">获取验证码</div> -->
        <div class="btn" @click="confirm">确定</div>
      </div>
      <div class="clubText">汽修江湖，汽修大侠的武林集结地</div>
    </div>
    <div class="mask"  v-if="isShowDialog">
      <div class="dialog">
        <h1>你的账号已绑定成功，无需再次绑定</h1>
        <h2>温馨提示：点击左上角关闭页面即可</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { isWeiXin, isIOS } from 'utils/wx'
import { Toast } from 'vant'
import { goWXStaticAuth } from 'utils/wx'
import { getUrlParam } from 'utils/index'
import { bindSendVCode, getClubWxBind, clubWxIsBind, wxGetJsApiSign} from 'api/user'
import 'vant/lib/toast/style'
import { getSessionStorage } from 'utils/store'
import wx from 'weixin-js-sdk'

export default {
  data () {
    return{
      sendCaptchaEnabled: true,
      counterTimer: 60,
      phone:'',
      vcode:'',
      sending: false,
      isBind:false,
      openId:'',
      isShowDialog:false,
      appID:'wx1e1b61b463326dfa',
      // appID:process.env.VUE_APP_QIXIUWXH5_APPID,
      timestamp: '', // 必填，生成签名的时间戳
      nonceStr: '', // 必填，生成签名的随机串
      signature: '',
      throttle:false// 控制不可多次触发点击事件
    }
  },
  mounted () {
    this.geKeyboardStatue()
  },
  created () {
    this.token = getSessionStorage('token')
    if (isWeiXin()) {
      const params = getUrlParam()
      if (!params['code']) {
        // console.log(goWXStaticAuth)
        goWXStaticAuth(process.env.VUE_APP_QIXIUWXH5_APPID, '/g/qixiu-club-bind')
      } else {
        const params = getUrlParam()
        const code = params['code']
        let param = {
          code:code
        }
        clubWxIsBind(param).then(res => {
          console.log(res,'res==============')
          if(!res.data.success) return
          this.isBind = res.data.data.isBind
          this.openId = res.data.data.openId
          if(this.isBind){
            console.log(this.isBind,'isbind=============')
            this.isShowDialog = true
          }
        })
      }
    }
    this.getConfigInfo()
  },
  methods:{
    // 获取config相关权限字段
    getConfigInfo () {
      const url = encodeURIComponent(window.location.href)
      console.log(url)
      wxGetJsApiSign(url).then(res => {
        // console.log(res,'+++++++++++++++++++res')
        if(!res.data.success) return
        this.timestamp = res.data.data.timestamp
        this.nonceStr = res.data.data.nonceStr
        this.signature = res.data.data.signature
      })
    },
    // 手机号验证
    getIphoneTrim () {
      if (!this.phone.trim()) {
        Toast("请填写手机号码")
        return false
      }
      if (!/^1\d{10}$/.test(this.phone.trim())) {
        Toast("请输入正确的手机号")
        return false
      }
      return true
    },
     // 验证码验证
    getVcodeTrim () {
     if (!this.vcode.trim()) {
        Toast("请输入正确的验证码")
        return false
      }
      if (!/^\d{6}$/.test(this.vcode.trim())) {
        Toast("请输入正确的验证码")
        return false
      }
      return true
    },
    // 键盘弹起与收回后，控制页面归位
    geKeyboardStatue () {
      // 是否归为
      let isReset = true
      // console.log('isIOS', isIOS())
      // console.log('isWeiXin', isWeiXin())
      if (isIOS() && isWeiXin()) {
        document.body.addEventListener('focusin', () => {
          // 软键盘弹出的事件处理
          isReset = false
          console.log('软键盘弹出')
        })
        document.body.addEventListener('focusout', () => {
          // 软键盘收起的事件处理
          isReset = true
          console.log('软键盘收起')
          setTimeout(() => {
            // 当焦点在弹出层的输入框之间切换时先不归位
            if (isReset) {
              // 失焦后强制让页面归位
              window.scroll(0, 0)
            }
          }, 300)
        })
      }
    },
    // 倒计时
    getTimeCaptchaEnable () {
      this.sendCaptchaEnabled = false
      this.timer = setInterval(() => {
        if (this.counterTimer <= 0) {
          this.counterTimer = 60
          this.sendCaptchaEnabled = true
          clearInterval(this.timer)
        }
        this.counterTimer--
      }, 1000)
    },
    // 获取验证码
    sendCaptcha () {
      if (!this.sendCaptchaEnabled) return
      if (!this.getIphoneTrim()) return
      bindSendVCode(this.phone).then(res => {
        // console.log(res,'11111111111111')
        if(!res.data.success){
          Toast("请先下载并注册大大汽修")
        }
        })
      this.getTimeCaptchaEnable()
    },
    // 点击确定
    confirm () {
      this.getIphoneTrim()
      if(this.getIphoneTrim()){
        this.getVcodeTrim()
      }
      // this.getIphoneTrim()
      // this.getVcodeTrim()
      if(this.throttle) return
      this.throttle = true
      if(!this.getIphoneTrim() || !this.getVcodeTrim()) return
      // if(this.isBind) return
      const params = {
        openId:this.openId,
        phone:this.phone,
        vcode:this.vcode
      }
      getClubWxBind(params).then(res => {
        this.throttle = false
        // console.log(this.appID,this.timestamp,this.nonceStr,this.signature,'========================')
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: this.appID, // 必填，公众号的唯一标识
          timestamp: this.timestamp, // 必填，生成签名的时间戳
          nonceStr: this.nonceStr, // 必填，生成签名的随机串
          signature: this.signature, // 必填，签名
          jsApiList: ["closeWindow"] // 必填，需要使用的JS接口列表
        })
        wx.error(function (res) {
          console.log('wxerr',res)
        })
        // console.log(res,'33333333')
        if(!res.data.success){
          setTimeout(() => {
            Toast(`${res.data.desc}`)
          }, 100)
        }else {
          Toast('绑定成功')
          setTimeout(() => {
            wx.closeWindow()
          },2000)
        }
      }).catch(() =>{
        this.throttle = false
      }
    )
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index.scss";
.container{
  width: 100%;
  height: 100vh;
  background: url(~images/garage/qixiuClub-bgBottom.png) no-repeat;
  background-size: cover;
  .top{
    width: 100%;
    height: px2rem(618px);
    background: url(~images/garage/qixiuClub-bgTop.png) no-repeat;
    background-size: cover;
  }
  .bottom{
    width: 100%;
    height: px2rem(716px);
    background: url(~images/garage/qixiuClub-bgBottom.png) no-repeat;
    background-size: cover;
    padding: 0 px2rem(82px);
    box-sizing: border-box;
    .content{
      width:px2rem(586px);
      height:px2rem(430px);
      background:rgba(225,243,249,1);
      box-shadow:px2rem(5px) px2rem(2px) px2rem(8px) px2rem(4px) rgba(0,0,0,0.16);
      border-radius:px2rem(10px);
      box-sizing: border-box;
      padding:px2rem(54px) px2rem(29px) px2rem(60px) px2rem(29px);
      .phone{
        -webkit-appearance: none;
        outline:none;
        width:px2rem(528px);
        height:px2rem(80px);
        background:linear-gradient(180deg,rgba(177,224,252,1) 0%,rgba(162,211,241,1) 66%,rgba(116,173,229,1) 100%);
        border-radius:px2rem(13px);
        font-size:px2rem(30px);
        color:rgba(57,57,61,1);
        padding-left: px2rem(30px);
        box-sizing: border-box;
        // padding:px2rem(20px);
      }
      input::placeholder{
        color:rgba(111,149,179,1);
        line-height: normal ;
      }
      .row{
        display: flex;
        height:px2rem(118px);
      }
      .vcode{
        flex:1;
        width: 50%;
        -webkit-appearance: none;
        outline:none;
        height:px2rem(80px);
        background:linear-gradient(180deg,rgba(177,224,252,1) 0%,rgba(162,211,241,1) 66%,rgba(116,173,229,1) 100%);
        border-radius:px2rem(13px) 0 0 px2rem(13px);
        font-size:px2rem(30px);
        color:rgba(57,57,61,1);
        padding-left: px2rem(30px);
        box-sizing: border-box;
        margin-top: px2rem(38px);
      }
      .getBtn{
        flex:1;
        width: 50%;
        height:px2rem(80px);
        background:linear-gradient(180deg,rgba(177,224,252,1) 0%,rgba(162,211,241,1) 66%,rgba(116,173,229,1) 100%);
        border-radius: 0 px2rem(13px) px2rem(13px) 0;
        display: inline-block;
        font-size:px2rem(30px);
        color:rgba(255,255,255,1);
        line-height: px2rem(80px);
        padding-right:px2rem(30px);
        box-sizing: border-box;
        text-align: right;
        margin-top: px2rem(38px);
      }
      .getBtnColor{
        color:rgba(111,149,179,1);
      }
      .btn{
        width:px2rem(528px);
        height:px2rem(80px);
        background:linear-gradient(180deg,rgba(184,224,251,1) 0%,rgba(126,181,230,1) 30%,rgba(35,114,222,1) 100%);
        border-radius:px2rem(13px);
        font-size:px2rem(34px);
        font-weight: bold;
        color:rgba(255,255,255,1);
        line-height: px2rem(80px);
        text-align: center;
        margin-top: px2rem(38px);
      }
    }
    .clubText{
      width:px2rem(586px);
      height:px2rem(40px);
      font-size:px2rem(28px);
      font-weight:500;
      color:rgba(255,255,255,1);
      line-height:px2rem(40px);
      text-align: center;
      margin-top:px2rem(45px);
    }
  }
  .mask{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding:0 px2rem(70px);
    box-sizing: border-box;
    .dialog{
      width: px2rem(610px);
      height: px2rem(300px);
      background-color: #fff;
      border-radius: px2rem(20px);
      font-size: px2rem(30px);
      h1{
        margin-top:px2rem(120px);
        font-weight: bold;
      }
      h2{
        margin-top:px2rem(20px);
        font-weight: normal;
        color: rgb(95, 86, 86);
        font-size: px2rem(24px);
      }
    }
  }
}
</style>